exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/About/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/Contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-header-index-tsx": () => import("./../../../src/pages/Header/index.tsx" /* webpackChunkName: "component---src-pages-header-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-box-index-tsx": () => import("./../../../src/pages/InfoBox/index.tsx" /* webpackChunkName: "component---src-pages-info-box-index-tsx" */),
  "component---src-pages-nav-bar-index-tsx": () => import("./../../../src/pages/NavBar/index.tsx" /* webpackChunkName: "component---src-pages-nav-bar-index-tsx" */),
  "component---src-pages-treatment-index-tsx": () => import("./../../../src/pages/Treatment/index.tsx" /* webpackChunkName: "component---src-pages-treatment-index-tsx" */)
}

